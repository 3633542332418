import imageConstruction from "./image/in_contruction.gif"
import "./App.css"

function App() {
    return (
        <div className="background">
            <div className="container">
                <div className="container__picture">
                    <picture>
                        <img src={imageConstruction} alt="in_contruction"></img>
                    </picture>
                </div>
                <div className="container__label">
                    <p className="label__first">EN CONSTRUCCIÓN</p>
                    <p className="label__copy">©mardecera {new Date().getFullYear()}</p>
                </div>
            </div>
        </div>
    )
}

export default App;